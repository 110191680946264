@font-face {
  font-family: "Gilroy";
  src: url("../font/Gilroy-Black.woff2") format("woff2"), url("../font/Gilroy-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../font/Gilroy-Bold.woff2") format("woff2"), url("../font/Gilroy-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../font/Gilroy-BlackItalic.woff2") format("woff2"), url("../font/Gilroy-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../font/Gilroy-Bold.woff2") format("woff2"), url("../font/Gilroy-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../font/Gilroy-ExtraboldItalic.woff2") format("woff2"), url("../font/Gilroy-ExtraboldItalic.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../font/Gilroy-Extrabold.woff2") format("woff2"), url("../font/Gilroy-Extrabold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../font/Gilroy-BoldItalic.woff2") format("woff2"), url("../font/Gilroy-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../font/Gilroy-HeavyItalic.woff2") format("woff2"), url("../font/Gilroy-HeavyItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../font/Gilroy-Medium.woff2") format("woff2"), url("../font/Gilroy-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../font/Gilroy-Light.woff2") format("woff2"), url("../font/Gilroy-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy-Bold";
  src: url("../font/Gilroy-Heavy.woff2") format("woff2"), url("../font/Gilroy-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../font/Gilroy-MediumItalic.woff2") format("woff2"), url("../font/Gilroy-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../font/Gilroy-LightItalic.woff2") format("woff2"), url("../font/Gilroy-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: url("../font/Gilroy-Regular.woff2") format("woff2"), url("../font/Gilroy-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../font/Gilroy-RegularItalic.woff2") format("woff2"), url("../font/Gilroy-RegularItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy-Semi-Bold";
  src: url("../font/Gilroy-Semibold.woff2") format("woff2"), url("../font/Gilroy-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../font/Gilroy-SemiboldItalic.woff2") format("woff2"), url("../font/Gilroy-SemiboldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: url("../font/Gilroy-UltraLightItalic.woff2") format("woff2"), url("../font/Gilroy-UltraLightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: url("../font/Gilroy-ThinItalic.woff2") format("woff2"), url("../font/Gilroy-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: url("../font/Gilroy-Thin.woff2") format("woff2"), url("../font/Gilroy-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../font/Gilroy-UltraLight.woff2") format("woff2"), url("../font/Gilroy-UltraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

body:not(.parallax) {
  background: url("../img/bg.jpg") center 0 #e0cdbc no-repeat;
  font-family: "Gilroy";
  font-size: 20px;
  background-size: cover;
  color: #140d12;
  font-weight: 300;
  line-height: 1.2;
}

html{
  scroll-behavior: auto !important;
}

#anasayfa{
  scroll-behavior: smooth !important;
}

.body_not {
  /* background: url("../img/bg_not.jpg") center 0 #e0cdbc no-repeat; */
  background-size: cover !important;
}

a {
  color: #140d12;
  text-decoration: none;
}

a:hover {
  color: #140d12;
}

h1,
h2,
h3 {
  font-family: "Gilroy-Semi-Bold";
}

header .dropdown-menu{
  background-color: #e0ad91;
}
header .dropdown-item:active,header .dropdown-item:hover {
  background-color: #e16f30;
}
header .navbar-nav{
  width: 80%;
}

@media screen and (max-width: 1200px) {
  header .mobileRoot{
    display:flex;
    justify-content: space-between;
    width:100%;
  }
  header .navbar-nav{
    width: 100%;
    padding-right: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  header .dropdown-menu{
    background: transparent;
    position: relative;
    width: 100%;
    border: 0;
  }
  header .dropdown{
    flex-direction: column;
  }
  header .dropdown-menu a{
  color: rgba(255,255,255,.55);
  padding: .5rem 1rem;
  text-align: center;
  font-size:18px;
  }
  header .dropdown-item:active,header .dropdown-item:hover {
    background-color:transparent;
    color: rgba(255,255,255,.75);
  }
}

.asamaPopup {
  display: flex;
  flex-direction: column;
}
.asamaPopup .close {
  align-self: flex-end;
  position: relative;
  margin-bottom:20px;
}
.asamaPopup .close img {
  width: 70px;
}

.asamaPopup b {
  font-weight: 800;
  font-style: italic;
}

.asamaPopup .heading h1 {
  font-size: 60px;
  display: inline-block;
}
.asamaPopup .heading span {
  font-weight: 900;
  font-size: 80px;
}
.asamaPopup p {
  line-height: 1.5em;
}
.Tilt-inner{
  position: relative;
}
.leftBottomIcon{
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translate(-100%, 30%);
  z-index:99;
  width:115px;
}
.rightBottomIcon{
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(-50%, 30%);
  z-index:99;
  width:115px;
}
@media (max-width: 968px) {
  .leftBottomIcon, .rightBottomIcon{
    position: relative;
    left: 50%;
    transform: translate(-50%,-10%);
  }
}

.uzuntuPopup {
  display: flex;
  flex-direction: column;
}
.uzuntuPopup p {
  font-size: 24px;
}
.uzuntuPopup p.refs{
  font-size: 16px;
}
.uzuntuPopup h1 {
  position: relative;
  left: -5%;
  margin-bottom: 0.5em;
}

#overlay {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #e0cdbc;
  transition: 1s 0.4s;
}

#progstat {
  font-size: 144px;
  letter-spacing: 3px;
  position: absolute;
  top: 50%;
  margin-top: -144px;
  width: 100%;
  text-align: center;
  color: #1d1219;
  font-weight: 600;
}

#progress {
  width: 100%;
  top: 50%;
  position: absolute;
  margin-top: 20px;
}

#thecode{
  position: fixed;
  right: 5px;
  bottom: 5px;
  font-size: 14px;
  color: #000;
  text-shadow: 1px 1px 1px #fff;
  z-index: 999999;
} 

#progress p {
  width: 40%;
  text-align: center;
  margin: 30px auto;
}

.vertical-center-logo {
  margin: 0; 
  position: absolute;
  top: 40%;
  -ms-transform: translateY(-40%);
  transform: translateY(-40%);
  left: 30%;
}

.center-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.titleAnchor{
  margin-bottom: 50px;
  display:flex;
  align-items: center;
}
.titleAnchor img{
  margin-right:1rem;
  margin-left:1rem;
}

.title {
  font-weight: 600 !important;
  font-size: 70px;
}

.subtitle {
  font-size: 40px;
  font-weight: 600;
}

.border-right {
  border-right: 1px solid #1d1219;
}

.border-left {
  border-left: 1px solid #1d1219;
}

.col-image {
  flex: 0 0%;
  position: absolute;
  width: auto;
  margin: 0;
}

.col-image:nth-child(1) {
  left: 0;
}

.col-image:nth-child(2) {
  left: 20%;
  margin-top: 60px;
}

.col-image:nth-child(3) {
  left: 40%;
  margin-top: 0px;
}

.col-image:nth-child(4) {
  left: 60%;
  margin-top: 60px;
}

.col-image:nth-child(5) {
  left: 80%;
  margin-top: 0;
}

.col-image blockquote {
  display: none;
}

.col-image figcaption {
  margin-left: 20px;
}

.col-image figcaption span {
  margin-top: -95px;
  display: table;
  font-size: 65px;
  font-weight: 600;
  margin-bottom: 30px;
}

.col-image-mobile {
  margin-top: -70px;
}

.col-image:nth-child(1) {
  left: 0;
}

.col-image:nth-child(2) {
  left: 20%;
  margin-top: 60px;
}

.col-image:nth-child(3) {
  left: 40%;
  margin-top: 0px;
}

.col-image:nth-child(4) {
  left: 60%;
  margin-top: 60px;
}

.col-image:nth-child(5) {
  left: 80%;
  margin-top: 0;
}

.col-image-mobile blockquote {
  display: none;
}

.col-image-mobile h6 {
  font-size: 20px !important;
  margin-top: 65px;
}

.col-image-mobile h6 span {
  display: table;
  font-size: 30px !important;
  font-weight: 600;
  margin-bottom: 15px;
}

@media (max-width: 1632px) {
  .navbar {
    font-size: 18px;
  }
}

@media (min-width: 0px) {
  .sticky-xs-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.navbar-dark .navbar-toggler {
  border-color: transparent;
}

.bg-dark {
  background-color: #1d1219 !important;
}

footer {
  font-size: 14px;
  color: #e1cdbc;
  display: flex;
  padding-bottom: 20px;
  background: rgb(122, 109, 103);
  background: linear-gradient(0deg, rgba(122, 109, 103, 1) 55%, rgba(225, 205, 188, 0) 100%);
}

.wp-block-image img{
  max-width: 100%;
  height: auto;
}

.font30 {
  font-size: 30px;
}

.font15 {
  font-size: 15px;
}

.withHover{
  filter:saturate(0);
  transition: filter 0.5s;
}

.withHover:hover{
  filter:saturate(1);
}

#bulletin_1 .col {
  height: 248px;
  font-size: 25px;
  position: relative;
  padding-top: 10px;
  flex: 1 0 18% !important;
}

#multipl_1 .col {
  font-size: 25px;
  position: relative;
  padding-top: 10px;
  font-weight: 600;
}
#multipl_1 .Tilt-inner{
  position: absolute;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 96%;
    text-align: center;
    padding:20px;
}
#multipl_1 .col:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

#multipl_1 .col span {
  font-size: 40px;
  bottom: 0;
  position: absolute;
  left: 15px;
  font-weight: 300;
}

#multipl_1 .col:nth-child(n),
#bulletin_1 .col:nth-child(n) {
  background-color: #cdbbab;
}

#multipl_1 .col:nth-child(2n),
#bulletin_1 .col:nth-child(2n) {
  background-color: #c1ad9f;
}

#multipl_1 .col:nth-child(3n),
#bulletin_1 .col:nth-child(3n) {
  background-color: #b5a197;
}

#multipl_1 .col:nth-child(4n),
#bulletin_1 .col:nth-child(4n) {
  background-color: #a7958b;
}

#multipl_1 .col:nth-child(5n),
#bulletin_1 .col:nth-child(5n) {
  background-color: #a7958b;
}

#multipl_1 .col:nth-child(6n),
#bulletin_1 .col:nth-child(6n) {
  background-color: #b5a197;
}

#multipl_1 .col:nth-child(7n),
#bulletin_1 .col:nth-child(7n) {
  background-color: #c1ad9f;
}

#multipl_1 .col:nth-child(8n),
#bulletin_1 .col:nth-child(8n) {
  background-color: #cdbbab;
}

#multipl_1 .col:nth-child(9n),
#bulletin_1 .col:nth-child(9n) {
  background-color: #cdbbab;
}

#multipl_1 .col:nth-child(10n),
#bulletin_1 .col:nth-child(10n) {
  background-color: #c1ad9f;
}

.mtb150 {
  margin-top: 150px !important;
  margin-bottom: 150px !important;
}

.mt150 {
  margin-top: 150px !important;
}

.mt200 {
  margin-top: 200px !important;
}

.mt250 {
  margin-top: 250px !important;
}

.mb200 {
  margin-bottom: 200px !important;
}

.mb150 {
  margin-bottom: 150px !important;
}

.form-input {
  background-color: transparent;
  border-left: none;
  border-top: none;
  border-right: none;
  border-bottom: 1px solid #ac6926;
}

.form-control:focus {
  background-color: transparent;
}

.send {
  background: url("../img/send.png") no-repeat;
  width: 258px;
  height: 69px;
  border: none;
  margin-top: 20px;
  float: right;
}

.rounded-start-10 {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.rounded-end-10 {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

::-webkit-input-placeholder {
  text-align: center;
  color: #212529;
}

:-moz-placeholder {
  /* Firefox 18- */
  text-align: center;
  color: #212529;
}

::-moz-placeholder {
  /* Firefox 19+ */
  text-align: center;
  color: #212529;
}

:-ms-input-placeholder {
  text-align: center;
  color: #212529;
}

.bd-sidebar {
  margin-top: 50px;
}

#sidebar{
  justify-content: flex-end;
}

.lists {
  height: 550px;
  overflow-y: auto;
  list-style: none;
  display: block;
}

.lists .item {
  margin-bottom: 20px;
  padding-left: 20px;
  margin-top: 25px;
}

.lists .item  a.active{
  color: #e0703c;
}

.halk .lists .item  a.active{
  color: #ed1c24;
}

.lists .item h4 {
  font-family: Gilroy;
}

.lists .item span {
  font-size: 15px;
  margin-bottom: 20px;
}

.lists::-webkit-scrollbar {
  display: block;
  width: 8px;
}

/* Track */
.lists::-webkit-scrollbar-track {
  background: #4f4d46;
  border-left: 3px solid #e0cdbc;
  border-right: 3px solid #e0cdbc;
}

/* Handle */
.lists::-webkit-scrollbar-thumb {
  background: #e0703c;
  border-radius: 5px;
}

.halk .lists::-webkit-scrollbar-thumb{
  background: #ed1c24;
}

/* Handle on hover */
.lists::-webkit-scrollbar-thumb:hover {
  background: #e0703c;
  width: 20px;
}

.lists::-webkit-scrollbar-thumb:hover {
  background: #ed1c24;
}

.lists::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 40%;
}

.lists::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 40%;
}

.color-orange {
  color: #e0703c;
}

.bg-image {
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  height: 110vh;
}

#sadness {
  position: relative;
}

#sadness h6 {
  font-size: 30px;
  font-weight: 600;
}

.harita_model {
  background: #e1cdbc;
  opacity: 0.8;
}

.haritaList .list-group-item{
  font-size:14px;
  background-color: transparent;
}

.modal h1 {
  font-size: 60px;
  font-weight: bolder;
}
.modal-backdrop {
  background-color: #e1cdbc;
}
.modal-backdrop.show {
  opacity: 0.9;
}

.harita_model .modal-dialog .modal-content {
  background: transparent;
  border: none;
}
.popup_model_video {
  background: #e1cdbc;
  opacity: 0.8;
}
.popup_model_video .modal-dialog .modal-content {
  background: transparent;
  border: none;
}
.popup-close {
  background: url("../img/close.png") no-repeat 0 0;
  width: 210px;
  height: 80px;
  cursor: pointer;
  display: table;
  float: right;
  border: none;
}
.cursor {
  cursor: pointer;
}
.text-justify {
  text-align: justify;
}
#mapid {
  width: 100%;
  height: 500px;
  position: relative;
  border: 1px solid #e1cdbc;
}

.city-select {
  background: transparent;
  border: 1px solid #000;
  border-radius: 0;
  font-size: 25px;
  font-weight: 600;
}

select option {
  background: transparent;
  border: 1px solid #000;
  width: 100px;
}

option:not(:checked) {
  background-color: #cab9a9;
}

option:checked {
  background-color: #b4a396;
}
.thepodcast .analist{
  text-align:left;
}
.thepodcast .anatitle,.thepodcast .anatarih{
  color:#e06f3c;
}
.thepodcast .anatarih{
font-weight:bold;
}
.thepodcast .analist li{

  padding:30px 0;
  border-top:1px solid #000;
}
.thepodcast .anaplayPause{
  cursor:pointer;
}

.form-floating>.form-control:focus, .form-floating>.form-control:not(:placeholder-shown) {
  padding-top: 3rem;
  padding-bottom: 1.4rem;
}

@media only screen and (max-width: 768px) {
  .simpleContent .border-left{
    border:0;
  }
  #multipl_1 .Tilt-inner{
    position:relative;
    height:auto;
    text-align: left;
  }
  .bg-image {
    height: 50vh;
  }
  .titleAnchor{
    margin-bottom:24px;
    justify-content: center;
  }
  .titleAnchor img{
    height: 30px;
  }
  .font30{
    font-size:20px;
  }

  .r-font {
    font-size: 34px;
    text-align: center;
  }
  #progress p {
    width: 100%;
  }

  .mtb150 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .mt150 {
    margin-top: 50px !important;
  }

  .mt200 {
    margin-top: 75px !important;
  }

  .mt250 {
    margin-top: 85px !important;
  }

  .mb200 {
    margin-bottom: 75px !important;
  }

  .mb150 {
    margin-bottom: 50px !important;
  }
  #multipl_1 .col:after{
    display:none;
  }
  #multipl_1 .col {
    height:  auto;
    font-size: 20px;
    position: relative;
    font-weight: 600;
    flex: none;
    padding: 5px 10px;
  }
  #multipl_1 .col span{
    position: relative;
    left: 0;
    display: block;
  }

  #sadness {
    position: static;
    bottom: 0;
  }

  footer {
    background: #c5af99;
    color: #000000;
  }
}

.navbar-nav .nav-item {
  flex: 1;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

b {
  font-weight: 600;
}
.popupAc {
  cursor: pointer;
  text-align: left;
}

button {
  background: transparent;
  border: 0;
}

.userSelection h2 {
}

.scrolldown {
  position: fixed;
  left: 50%;
  margin-left: -38px !important;
  top: 57vh;
  z-index: 999;
}

.arrow {
  text-align: center;
  margin: 8% 0;
}
.bounce {
  animation: bounce 3s infinite;
}

.modal-open .modal{
  /* pointer-events:none; */
}

@keyframes bounce {
  0% {
    opacity: 0;
  }
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    opacity: 1;
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
  100% {
    opacity: 0;
  }
}

#thevideo {
  pointer-events: auto !important;
}
#thevideo2 {
  display: none;
  position: absolute;
  opacity: 0;
  transition: opacity 1s;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999 !important;
  outline: 0;
  pointer-events: none;
}
.video_active {
  opacity: 1 !important;
  pointer-events: auto !important;
}

.homePerde {
  position: absolute;
  width: 50%;
  height: 50vh;
  transform: translate(50%, 40%);
  z-index: 999;
}

.loginregister .verified{
  font-size: 1.5rem;
  font-weight: bold;
  color: #e06f3c;
  text-shadow: 1px 1px 0px #6f371d;
  text-align: center;
}

.loginregister input,
.loginregister select,#bilgi input,#bilgi select {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #7e6f6a;
  font-size: 20px;
  padding: 10px 0;
  margin-bottom: 10px;
  outline: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

.loginregister input:focus{
    box-shadow: 0 2px 0px #e06f3c;
    outline:0;
    border-bottom:0;
}
.loginregister .form-check{
    border-radius: 5px;
    padding-left: 2em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

.loginregister .form-check.invalid{
  border: 1px solid #dc3545;
}
.loginregister .form-check.invalid .invalid-feedback{
  display:block;
  text-align:left;
}
.loginregister .form-check-label a{
  text-decoration: underline;
  font-weight:bold;
}

.loginregister .form-control.is-invalid{
    border-width:2px;
}

 .loginregister input[type="checkbox"] {
 width: 1em;
    height: 1em;
    margin-top: .25em;
    vertical-align: top;
    border: 1px solid rgba(0,0,0,.5);
} 

.loginregister .error {
  color: #dc3545;
  font-weight: bold;
}

.loginregister .dabutton ,.loginok .dabutton{
  color: #e06f3c;
  font-weight: bold;
  border: 1px solid #e06f3c;
  padding: 1rem 2rem;
  transition: color 0.2s, background-color 0.2s;
}

.loginregister .dabutton:hover ,.loginok .dabutton:hover{
  color: #fff;
  background-color: #e06f3c;
}

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 70px;
  height: 70px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.halk .lds-spinner div:after {
  background: #ed1c24;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #e06f3c;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


@media screen and (max-width: 768px) {
  .img-mobil-ana{
    max-width: 70%;
    margin: 0 auto;
    display: block;
  }
  .asamaPopup .heading h1{
    font-size:40px;
  }
  .asamaPopup .heading span{
    font-size:50px;
  }.asamaPopup p{
    font-size: 18px;
    line-height: 1.3em;
  }
  .uzuntuPopup h1 {
    left:0;
  }
  .uzuntuPopup p{
    font-size:20px;
  }
  .subtitle{
    font-size:26px;
  }
    .scrolldown {
      right: 10px;
      top: 60px;
    }
    .scrolldown img {
      width: 40px;
    }

    .modal h1 {
        font-size: 36px;
      }

      .modal button img{
        width: 100%;
      }
  }

  .navbar-nav{
      text-align: right;
      direction: unset;
      padding-right: 1rem;
  }

  .simpleContent strong{
    font-weight:bold;
  }

  .wp-block-table table{
    width:100%;
    margin:1rem 0;
    font-size:1.1rem;
  }
  .wp-block-table table,   .wp-block-table th,   .wp-block-table td {
    border-spacing: 2px;
    border-collapse: collapse;
    border:2px solid #7a6d67;
  }
  .wp-block-table td{
    padding:0.6rem;
  }