body.parallax{
  padding: 0;
  margin: 0;
  min-height: 700vh;
  background-color: #000;
  overflow: hidden;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../assets/font/Gilroy-Light.woff2") format("woff2"),
    url("../../assets/font/Gilroy-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

#videoVersions{
  font-size: 21px;
  padding: 5px;
  border-radius: 5px;
  margin-top:20px;
}

.parallax {
  .avoid-clicks {
    pointer-events: none;
  }
  img {
    width: 100%;
  }
  #portals {
    position: fixed;
    right: 30px;
    bottom: 30px;
    z-index: 99999;
    padding: 15px;
    border-radius: 10px;
    background: #e0cdbc;
    align-items: center;
    justify-content: center;
    font-family: "Gilroy";
    img {
      width: auto;
    }
    a {
      line-height: 1em;
      color: #000;
      font-size: 20px;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  #main {
    overflow: hidden;
    height: 100vh;
    position: fixed;
    width: 100%;
    height: 100%;
    transform: perspective(500px);
  }
  .giris {
    z-index: 10;
    position: relative;
    pointer-events: none;
    > div {
      position: absolute;
      left: 0;
      top: 0;
      transform-origin: center center;
      pointer-events: none;
      width: 100%;
    }
  }
  .fuaye {
    z-index: 9;
    position: relative;
    transform: perspective(500px) translate3d(0px, 0px, -400px) !important;
    overflow: hidden;
    pointer-events: none;
    > div {
      position: absolute;
      left: 0;
      top: 0;
      transform-origin: center center;
      pointer-events: none;
      width: 100%;
    }
  }
  .salon {
    z-index: 8;
    position: relative;
    transform: perspective(500px) translate3d(0px, 0px, -1200px) !important;
    overflow: hidden;
    > div {
      position: absolute;
      left: 0;
      top: 0;
      transform-origin: center center;
      pointer-events: none;
      width: 100%;
    }
  }
  #thevideo {
    pointer-events: auto !important;
  }
  #thevideo2 {
    display: none;
    position: absolute;
    opacity: 0;
    transition: opacity 1s;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999 !important;
    outline: 0;
    pointer-events: none;
    width: 960px;
  }
  .video_active {
    opacity: 1 !important;
    pointer-events: auto !important;
  }
  .popup {
    width: 100%;
    height: 100%;
    background: #e0cdbc91;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 99999;
    .inside {
      font-family: "Gilroy";
      width: 600px;
      border-radius: 15px;
      background: #e0cdbc;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 15px;
      text-align: center;
      img {
        width: auto !important;
      }
    }
  }
  #all {
    position: relative;
    overflow: hidden;
    transition: opacity 2s;
  }
  video {
    outline: 0;
  }
  .hideOpacity {
    opacity: 0;
  }
  .salon_anaperde {
    transform-origin: 83% center;
  }
  .salon_anaperde2 {
    transform-origin: 17% center;
  }
  .selector {
    position: absolute;
    left: 0;
    top: 0;
    transform-origin: center center;
    pointer-events: none;
    width: 100%;
  }
  .solkapi {
    transform-origin: center center;
  }
  .sagkapi {
    transform-origin: center center;
  }
  .mainScale {
    max-width: 1366px;
    margin: 0 auto;
    width: 100%;
    height: 100%;
  }
  .isik {
    animation-name: animateheader;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
  .isik2,.isik3,.isik4,.isik5,.isik6,.isik7 {
    animation-name: animateheader2;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
  .isik3{
    animation-duration: 3.5s;
    animation-name: animateheader;
  }
  .isik4{
    animation-duration: 4.5s;
  }
  .isik5{
    animation-duration: 5s;
    animation-name: animateheader;
  }
  .isik6{
    animation-duration: 6s;
    animation-name: animateheader;
  }
  .isik7{
    animation-duration: 4.5s;
    animation-name: animateheader;
  }
  
}

@keyframes animateheader2 {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0.9;
  }
  40% {
    opacity: 0;
  }
  60% {
    opacity: 0.9;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0.8;
  }
}

@keyframes animateheader {
  0% {
    opacity: 0.9;
  }
  20% {
    opacity: 0.6;
  }
  40% {
    opacity: 0.2;
  }
  60% {
    opacity: 0.7;
  }
  80% {
    opacity: 0.85;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 992px) {
  #thevideo2 {
    position: relative;
    transform: initial;
    left: 0;
    top: 0;
  }
  #thevideo2 video {
    width: 100%;
  }
}

